import styled from "styled-components";
import { P } from "../typography";

interface CardProps {
  padding?: paddingVariants;
  textAlign?: "center" | "left" | "right";
  style?: any;
  css?: any;
  background?: string;
}

type paddingVariants = "huge" | "big" | "medium" | "small" | "none";

export const Card = styled.div<CardProps>`
  background-color: ${({ theme }) => theme.colors["card_background"]};
  border: solid 1px #e4e8ed;
  align-self: self-start;
  border-radius: 8px;
  padding: ${({ padding }) => {
    switch (padding) {
      case "big":
        return "4.5rem 3.125rem";
      case "small":
        return "1rem 1.25rem";
      default:
        return 0;
    }
  }};
  text-align: ${({ textAlign }) => textAlign || "left"};
  width: 100%;
  max-width: 100%;
`;

export const CardHeader = styled.header<CardProps>`
  border-bottom: 1px solid #e4e8ed;

  background-color: ${({ background, theme }) =>
    background ? theme.colors[background] || background : "#f5f7fa"};
  padding: ${({ padding }) => {
    switch (padding) {
      case "big":
        return "1rem 3rem";
      case "small":
        return "1rem 1.25rem";
      case "none":
        return "0";
      default:
        // medium
        return "1rem 1.5rem";
    }
  }};
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px 8px 0 0;
`;

export const CardBody = styled.div<CardProps>`
  max-width: 100%;
  padding: ${({ padding }) => {
    switch (padding) {
      case "huge":
        return "3rem 6rem 1rem 6rem";
      case "big":
        return "1rem 3rem 1.5rem 3rem";
      case "medium":
        return "1rem 1.625rem 1.625rem 1.625rem";
      case "small":
        return "1rem 1.25rem 1.25rem 1.25rem";
      case "none":
        return "0";
      default:
        // big
        return "1rem 3rem 1.5rem 3rem";
    }
  }};
`;

export const CardFooter = styled.div<CardProps>`
  border-top: 1px solid #e4e8ed;
  max-width: 100%;

  padding: ${({ padding }) => {
    switch (padding) {
      case "huge":
        return "1.5rem 6rem 1.5rem 6rem";
      case "big":
        return "1rem 3rem 1.5rem 3rem";
      case "medium":
        return "1rem 1.625rem 1.625rem 1.625rem";
      default:
        // huge
        return "1.5rem 6rem 1.5rem 6rem";
    }
  }};
`;

export const CardTitle: React.FunctionComponent = ({ children }) => (
  <P font="body" weight="bold">
    {children}
  </P>
);

export const WrapperStyles = styled.div`
  [data-reach-accordion-item][data-state="open"] .card-header {
    border-radius: 8px 8px 0 0;
  }
  [data-reach-accordion-item][data-state="collapsed"] .card-header {
    border-radius: 8px;
  }
`;
