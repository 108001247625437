export function capitalizeFirst(str: string) {
  if (!str?.[0]) return "";
  return str[0].toUpperCase() + str.slice(1);
}

export function truncateText(text: string, length: number) {
  if (!text) return "";

  return (
    text.substring(0, length).trimEnd() +
    (text.trimEnd().length > length ? "..." : "")
  );
}
