import cookie from "cookie";
import faunadb from "faunadb";
import jwt from "jsonwebtoken";

const FAUNA_SECRET_COOKIE = "user_token";
const COOKIE_SALT = "eCbkkbCQqrI6tRcy";
export const COOKIE_MAX_AGE = 60 * 60 * 24 * 7; // 1 week

const PUBLIC_KEY = process.env.FAUNADB_PUBLIC_KEY || "";
const NOT_PRODUCTION =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV == "test";
const USE_SECURE = NOT_PRODUCTION ? false : true;

export const publicClient = new faunadb.Client({
  secret: PUBLIC_KEY,
});

// Used for any authed requests.
export const faunaClient = (secret: string) =>
  new faunadb.Client({
    secret,
  });

export function serializeFaunaCookie(
  userSecret: string,
  data: Record<string, unknown>,
) {
  const secret = jwtSign(userSecret, data);
  return cookie.serialize(FAUNA_SECRET_COOKIE, secret, {
    sameSite: "lax",
    secure: USE_SECURE,
    maxAge: COOKIE_MAX_AGE,
    httpOnly: true,
    path: "/",
  });
}

export function clearFaunaCookie(_: string) {
  return cookie.serialize(FAUNA_SECRET_COOKIE, "", {
    sameSite: "lax",
    secure: USE_SECURE,
    maxAge: -1,
    httpOnly: true,
    path: "/",
  });
}

export function getTokenFromCookie(headerCookie: any) {
  if (!headerCookie) return null;

  const cookies = cookie.parse(headerCookie ?? "");
  return jwtExtract(cookies[FAUNA_SECRET_COOKIE])?.secret;
}

export function getSessionFromCookie(headerCookie: any): {
  secret: string | null;
  data: Record<string, unknown> | null;
} | null {
  if (!headerCookie) return null;
  const cookies = cookie.parse(headerCookie ?? "");

  const session = jwtExtract(cookies[FAUNA_SECRET_COOKIE]);
  return {
    secret: session?.secret || null,
    data: session?.data || null,
  };
}

function jwtExtract(token: string) {
  try {
    const payload = jwt.verify(token, COOKIE_SALT) as {
      secret: string;
      data: Record<string, unknown>;
    };
    return payload || {};
  } catch (e) {
    return null;
  }
}

function jwtSign(userSecret: string, data: Record<string, unknown>) {
  return jwt.sign({ secret: userSecret, data }, COOKIE_SALT, {
    algorithm: "HS256",
    expiresIn: COOKIE_MAX_AGE,
  });
}
