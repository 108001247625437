import { useEffect } from "react";
import Router from "next/router";
import { User, UserRole } from "lib/auth/authContext/authContext.d";
import { compareRole, isAdmin } from "lib/helpers";
import { useAuth } from "./authContext";
import Routes from "../../routes";
import { getSessionFromCookie } from "../fauna-auth";

const URL = (process.env.URL as string) || "";

// dirty types to handle previous typings too
type Opts = {
  forLoggedIn?: boolean;
  fallback?: string;
} & { isPublic?: boolean; fallback?: string } & {
  forAdmin?: boolean;
  fallback?: string;
} & {
  role?: UserRole | UserRole[];
  fallback?: string;
};

function WithAuth(Component: any, opts: Opts = {}) {
  const { forLoggedIn = true, isPublic = false, forAdmin = false, role } = opts;

  function Wrapper(props: any) {
    const { setCurrentUser, user } = useAuth();

    useEffect(() => {
      if (!!user) return;
      if (props.currentUser) setCurrentUser(props.currentUser);
    }, []);

    if (!isPublic && forLoggedIn && !user) {
      return <></>;
    }
    return <Component {...props} />;
  }

  Wrapper.getInitialProps = async (ctx: any) => {
    if (typeof window === "undefined") {
      const { req, res } = ctx;
      const { secret: faunaSecret, data: userData } =
        getSessionFromCookie(req.headers.cookie ?? "") || {};

      if (!faunaSecret && !isPublic) {
        if (forLoggedIn) {
          res.writeHead(302, {
            Location: `${URL}${Routes.App.SignIn}?b=${req.url}`,
          });
          res.end();
          return "not-allowed";
        }
      }

      const fallback = `${URL}${opts.fallback || Routes.App.InvalidAccess}`;

      // Restrict page for not-logged in ONLY
      if (faunaSecret && !forLoggedIn) {
        res.writeHead(302, { Location: fallback });
        res.end();
        return "not-allowed";
      }

      let currentUser: User | null = null;
      if (userData?.id && userData?.role) {
        currentUser = userData as unknown as User;
      }

      // if only admin has access
      if (forAdmin && currentUser && !isAdmin(currentUser.role)) {
        res.writeHead(302, {
          Location: fallback,
        });
        res.end();
        return "not-allowed";
      }

      if (role && currentUser && !compareRole(role, currentUser.role)) {
        res.writeHead(302, {
          Location: fallback,
        });
        res.end();
        return "not-allowed";
      }

      const authProps = {
        currentUser,
      };

      if (Component.getInitialProps) {
        return Component.getInitialProps(ctx, authProps);
      }

      return authProps;
    }

    const response = await fetch(Routes.Api.Session, { method: "POST" });
    const { user: currentUser } = await response.json();
    const fallback = `${opts.fallback || Routes.App.InvalidAccess}`;

    // if no user and page is not public
    if (!currentUser && forLoggedIn && !isPublic) {
      Router.push(Routes.App.SignIn);
      return "not-allowed";
    }

    if (currentUser && !forLoggedIn) {
      Router.push(fallback);
      return "not-allowed";
    }

    if (role && currentUser && !compareRole(role, currentUser.role)) {
      Router.push(fallback);
      return "not-allowed";
    }

    const authProps = {
      currentUser,
    };

    if (Component.getInitialProps) {
      return Component.getInitialProps(ctx, authProps);
    }

    return authProps;
  };

  return Wrapper;
}

export default WithAuth;
