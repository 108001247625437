import styled from "styled-components";

interface Props {
  error?: boolean;
  disabled?: boolean;
}

export default function Toggle({ error, label, ...props }: any) {
  return (
    <StyledToggle error={error}>
      {typeof label === "function" ? label() : label}
      <input type="checkbox" {...props} />
      <div className="checkmark" />
    </StyledToggle>
  );
}

export const StyledToggle = styled.label<Props>`
  position: relative;
  width: 44px;
  height: 12px;
  display: flex;

  & > .checkmark {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme, error }) =>
      error ? theme.colors["error"] : "#e6e7ea"};
    transition: 0.4s;
    border-radius: 8px;
    text-align: left;

    &::before {
      position: absolute;
      content: "";
      width: 28px;
      height: 28px;
      border-radius: 16px;
      box-shadow: 0 2px 9px 0 rgba(72, 69, 88, 0);
      border: solid 1px
        ${({ theme, error }) => (error ? theme.colors["error"] : "#d8def2")};
      background-color: #fff;
      transition: 0.3s ease-out;
      top: -8px;
      left: -8px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 16px;
      background-color: #fff;
      transition: 0.3s ease-out;
    }
  }

  &[data-disabled="true"] > .checkmark {
    opacity: 0.75;
    cursor: not-allowed !important;
  }

  &:not([data-disabled="true"]):hover .checkmark::after,
  &:not([data-disabled="true"]) > input:focus + .checkmark::after {
    background-color: #08132f;
  }

  & > input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .checkmark::before {
      transform: translateX(30px);
    }

    &:checked + .checkmark::after {
      transform: translateX(30px);
      background-color: ${({ theme, error }) =>
        error ? theme.colors["error"] : "#08132f"};
    }
  }
`;
