import styled from "styled-components";
import { Card } from "components/Common/Card";

export const StylCard = styled(Card)`
  max-width: 610px;
  min-width: 610px;
  overflow: auto;
`;

export const StylButtonBox = styled.div`
  display: grid;
  grid-gap: 1.125rem;
  margin: 1.125rem 0 0 0;
`;

export const StylLoginLink = styled.a`
  text-decoration: underline;
`;

export const FormLogin = styled.form`
  width: 100%;
  padding: 1.125rem 0;
  text-align: left;
`;
export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;
export const FormGroup = styled.p`
  margin-bottom: 1rem;
`;
