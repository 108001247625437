import React, { ReactNode } from "react";
import Link from "next/link";
import styled from "styled-components";
import logoBlack from "../../assets/logos/logo-black.svg";
import GlobalStyles from "./GlobalStyles";

const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 4fr;
  background-color: ${({ theme }) => theme.colors.login_layout_background};
`;

const LayoutLoginFormBox = styled.div`
  width: 1px;
  z-index: 22;
  position: relative;
`;

const BackgroundImage = styled.div<{ img: string }>`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-image: ${({ img }) => `url("${img}")`};
`;

const ClearSide = styled.main`
  padding: 5rem 8rem;
`;

const LayoutLoginLogo = styled.img`
  width: 12rem;
  margin-bottom: 3rem;
`;

function LoginLayoutComponent({
  bgImage,
  children,
}: {
  bgImage?: string;
  children: ReactNode;
}): JSX.Element {
  return (
    <>
      <GlobalStyles />
      <Wrapper>
        <ClearSide>
          <Link href={"/"} passHref>
            <a>
              <LayoutLoginLogo src={logoBlack} />
            </a>
          </Link>
          <LayoutLoginFormBox>{children}</LayoutLoginFormBox>
        </ClearSide>

        {bgImage && <BackgroundImage img={bgImage} />}
      </Wrapper>
    </>
  );
}

export default LoginLayoutComponent;
