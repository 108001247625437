import { forwardRef, ForwardedRef } from "react";
import styled from "styled-components";
import { FormLabel } from "./styles";

interface CheckboxContainerProps {
  error?: boolean;
  disabled?: boolean;
}

const Checkbox = forwardRef(
  (props: any, ref: ForwardedRef<HTMLInputElement>): JSX.Element => {
    const { error, label, name, disabled, checked, ...rest } = props;

    return (
      <StyledCheckbox error={error} disabled={disabled} {...rest}>
        {typeof label === "function" ? label() : label}
        <input
          type="checkbox"
          ref={ref}
          name={name}
          disabled={disabled}
          checked={checked}
        />
        <div className="checkmark" />
      </StyledCheckbox>
    );
  },
);

export default Checkbox;

export const StyledCheckbox = styled(FormLabel)<CheckboxContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  margin-bottom: 5px;

  &:read-only {
    opacity: 0.72;
    outline: none;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  &:hover,
  &:focus {
    input:not(:disabled) ~ .checkmark {
      border-color: #000;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: #fff;
    border: 1px solid;
    border-color: ${({ theme, error }) =>
      error ? theme.colors["error"] : theme.colors["GRAY_200"]};
    border-radius: 8px;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  .checkmark:after {
    left: 10px;
    top: 5px;
    width: 7px;
    height: 13px;
    border: solid #000;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
