import styled, { css } from "styled-components";

export const FormDoubleGrid = styled.div`
  display: grid;
  grid-template-columns: 2.1fr minmax(400px, 1fr);
  grid-column-gap: 100px;
  padding-bottom: 7rem;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

interface FormGroupProps {
  direction?: "column" | "row";
  columnSpace?: string;
  nested?: boolean;
  hidden?: boolean;
  alignItems?: "center" | "start";
  gridGap?: string;
}
export const FormGroup = styled.div<FormGroupProps>`
  display: ${({ hidden }) => (hidden ? "none" : "grid")};
  padding-bottom: 1.125rem;
  align-content: center;
  align-items: ${({ alignItems }) => alignItems};
  ${({ direction, columnSpace, gridGap }) =>
    direction === "row"
      ? css`
          grid-template-columns: ${columnSpace || "1fr 3.8fr"};
          grid-gap: ${gridGap || "12px 16px"};
        `
      : css`
          grid-gap: ${gridGap || "12px 10px"};
        `}
  ${({ nested }) =>
    nested &&
    css`
      position: relative;
      padding-left: 100px;
      padding-bottom: 0.825rem;

      &:before {
        content: "";
        position: absolute;
        left: 30px;
        top: -1.525rem;
        border-left: 1px solid ${({ theme }) => theme.colors["dark_grey"]};
        border-bottom: 1px solid ${({ theme }) => theme.colors["dark_grey"]};
        opacity: 0.1;
        width: 50px;
        height: 100%;
      }
      &:first-of-type:before {
        height: 80%;
        top: -1rem;
      }
    `}
`;
FormGroup.defaultProps = {
  direction: "column",
  alignItems: "center",
};

type AlignItems = "center" | "start";

interface SummaryInfoGrouProps {
  alignItems?: AlignItems;
}

export const SummaryInfoGroup = styled.div<SummaryInfoGrouProps>`
  display: grid;
  grid-template-columns: 120px 1fr;
  padding-bottom: 1rem;
  align-items: ${({ alignItems }) => alignItems};
  column-gap: 0.5em;
`;

SummaryInfoGroup.defaultProps = {
  alignItems: "center",
};

interface FormLabelProps {
  light?: boolean;
}

export const FormLabel = styled.label<FormLabelProps>`
  font-size: 0.925rem;
  line-height: 1.79;
  color: ${({ theme, light }) =>
    light ? theme.colors["label_text_light"] : theme.colors["label_text"]};
  cursor: pointer;
`;

interface FormInputProps {
  error?: boolean;
  style?: any;
  ref?: any;
}

export const InputBase = css<FormInputProps>`
  color: ${({ theme }) => theme.colors["input_text__idle"]};
  will-change: color;
  transition: color 125ms ease;

  &:hover:not(:read-only, :disabled),
  &:focus:not(:read-only, :disabled) {
    color: ${({ theme }) => theme.colors["input_text__active"]};
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors["input_text__active"]};
  }
`;

export const FormInput = styled.input<FormInputProps>`
  ${InputBase};
  width: 100%;
  border-radius: 8px;
  border: solid 1px
    ${({ error, theme }) =>
      error ? theme.colors["error"] : "rgba(6, 25, 87, 0.3)"};
  padding: 0.825rem 1.825rem;

  &:hover:not(:read-only, :disabled),
  &:focus:not(:read-only, :disabled) {
    box-shadow: 0 2px 9px 0 rgba(72, 69, 88, 0.23);
  }

  &:read-only {
    opacity: 0.72;
    outline: none;
    background-color: #fcfcff;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors["input_placeholder"]};
  }
`;

export const FormTextArea = styled.textarea<FormInputProps>`
  ${InputBase};
  width: 100%;
  border-radius: 8px;
  border: solid 1px
    ${({ error, theme }) =>
      error ? theme.colors["error"] : "rgba(6, 25, 87, 0.3)"};
  padding: 0.825rem 1.825rem;
  resize: vertical;

  &:hover,
  &:focus {
    box-shadow: 0 2px 9px 0 rgba(72, 69, 88, 0.23);
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors["GRAY_400"]};
  }
`;

interface InputMessageProps {
  type: "error" | "success";
  style?: any;
}

export const InputMessage = styled.p<InputMessageProps>`
  color: ${({ type, theme }) => theme.colors[type]};
  padding: 0.2rem 0 0.5rem;
  font-size: 0.9rem;
  grid-column: 2/-1;
`;

export const FormErrorP = styled.p`
  color: ${({ theme }) => theme.colors["error"]};
  padding: 0.5rem 0;
`;

FormErrorP.defaultProps = {
  role: "alert",
};

export const FormSuccessP = styled.p`
  padding: 0.5rem 0;
  color: ${({ theme }) => theme.colors["success"]};
`;
