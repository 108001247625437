import React, { useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@reach/accordion";
import styled from "styled-components";
import ChevronTop from "../../../assets/icons/chevron-top.svg";
import { Card, CardHeader, WrapperStyles } from ".";

interface Props {
  header?: JSX.Element | (() => JSX.Element);
  hideButtonPosition?: "left" | "right";
  expandOnInit?: boolean;
  collapse?: boolean;
  showArrow?: boolean;
}

export const CardExpandable: React.FC<Props> = ({
  children,
  header,
  collapse,
  hideButtonPosition = "left",
  expandOnInit = true,
  showArrow = true,
  ...rest
}) => {
  const [visibleIndex, setIndex] = React.useState<number>(expandOnInit ? 0 : 1);

  function toggleExpanding(value: number) {
    if (visibleIndex === value) setIndex(value + 1);
    else setIndex(value);
  }

  const placeButtonLeft = hideButtonPosition == "left";

  useEffect(() => {
    if (collapse) {
      setIndex(1);
      return;
    }
    setIndex(0);
  }, [collapse]);

  return (
    <WrapperStyles>
      <Accordion index={visibleIndex} onChange={toggleExpanding}>
        <AccordionItem>
          <Card {...rest}>
            <Header className="card-header">
              {placeButtonLeft && showArrow && (
                <>
                  <ExpandButton />
                  <Spacer />
                </>
              )}

              {typeof header === "function" ? header() : header}

              {!placeButtonLeft && showArrow && (
                <>
                  <Spacer />
                  <ExpandButton />
                </>
              )}
            </Header>
            <AccordionPanel>{children}</AccordionPanel>
          </Card>
        </AccordionItem>
      </Accordion>
    </WrapperStyles>
  );
};

const ExpandButton = () => (
  <Button>
    <img src={ChevronTop} alt="hide" />
  </Button>
);

const Header = styled(CardHeader)`
  padding-left: 20px;
  padding-right: 16px;
`;

const Spacer = styled.div`
  display: inline-block;
  width: 21px;
`;

const Button = styled(AccordionButton)`
  ${({ theme }) => theme.presets["controlButton"]}
  margin-left: -9px;
  img {
    transition: transform 125ms ease-in;
    perspective: 200;
  }
  &[aria-expanded="true"] {
    img {
      transform: rotate3d(1, 1, 1, 0);
    }
  }
  &[aria-expanded="false"] {
    img {
      transform: rotate3d(1, 0, 0, 180deg);
    }
  }
`;
